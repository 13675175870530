<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Latest Targets
            .header-cta
                .flex.items-center
                    button.btn-default(@click="openWatchlist(true)") Watchlist: 100
                        IconSet(type="chevron-down")
                    .block
                        button.btn-primary.m-btn-icon(
                            v-if="checkFeatureByAcl('vehicle', 'create')"
                            @click="openModal(true)"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 New Target

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"                        
                        type="text"
                        placeholder="Search here..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                //- .field.mr-3( style="min-width: 180px;")
                //-     select.field-style()
                //-         option All Operator
                //-         option Operator 1
                //-         option Operator 2
                //-     .field-icon
                //-         IconSet(
                //-             type="chevron-down"
                //-         )

                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    .relative.field-style.w-full
                        date-range-picker(
                            v-if="dateRange.startDate && dateRange.endDate"
                            ref="picker"
                            v-model="dateRange"
                            @update="pickerUpdate($event)" 
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                            :date-format="dateFormat" 
                            :autoApply="true"
                            opens="right"
                            :ranges="default_ranges"
                        )
                            div(
                                slot="input" 
                                slot-scope="picker") 
                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                        .field-icon
                            IconSet(type="calendar")

                button.btn-primary.mr-3(
                    @click="filterList()"
                ) Filter                            

            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(
                                v-for="(th, index) in tableThead",
                                :key="index",
                                :class="(th.number && th.number === true ? 'number' : '') + (th.action && th.action === true ? ' thead-action' : '')"
                            ) {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")   

                        template(v-else-if="list.data && list.data.length > 0")
                            tr(v-for="(data, index) in list.data" :key="index")
                                td
                                    button.link(
                                        v-if="checkFeatureByAcl('vehicle', 'edit')"
                                        @click="editModal(data.id)"
                                    ) {{ data.plate_number ? data.plate_number : '-' }}
                                    template(v-else) {{ data.plate_number ? data.plate_number : '-' }}
                                td {{ data.created_by.name ? data.created_by.name : '-' }}
                                td {{ data.created_at ? data.created_at.substring(0, 19) : '-' }}
                                td
                                    router-link.btn.btn-small.btn-primary-o(
                                        v-if="checkFeatureByAcl('vehicle', 'show')"
                                        :to="'/vehicle/'+data.id+'/detail'"
                                    ) Search Detail
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available


            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                                )     

    //- popup 

    .popup-layer.is-show(v-if="openPopUp")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl {{ form_type ? 'Add' : 'Edit' }} Target Plate Police

                button.btn-icon-40(
                    @click="openModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold(for="plate_number") Plate Number
                    .field__form.w-full
                        input.field-style.w-full(type="text" placeholder="Write a plate number here..." v-model="model.plate_number")
                        .py-3
                            .message.text-xs.text-red-500(v-if="$v.model.plate_number.$error") This field is required!
                            .message.text-xs.text-red-500(v-if="error_plate_number !== ''") {{ error_plate_number }}
            //- .popup-container__body
                p.mb-8 Please choose the Plat Police to get Target's information
                
                label.mb-2.block.text-white Choose Plate Police
                
                .relative
                    //- searching
                    .field.w-full
                        input.field-style(
                            type="text"
                            placeholder="Search Plat Police here..."
                            v-model="typing_search"
                            @input="searchHandler()"
                        )
                        .field-icon(v-if="typing_search.length < 3")
                            IconSet(type="search")
                        
                        button.btn-close-search(v-else @click="clearSearch()")
                            IconSet(type="x")                    

                    //- search result
                    .box-search-result(v-if="typing_search.length >= 3 && !targetSelected")
                        span.text-xs.block.pt-4.px-5 Choose a Plat Police
                        ul
                            li(v-for="item in dataResult"): button( @click="itemSelected(e)") {{ item.plat}}
            .popup-container__footer
                .flex.justify-end.w-full
                    button.btn-primary(type="submit" @click="submit($event)") Save Target
                        
    
    .popup-layer.is-show(v-if="popUpWatchlist")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl WatchList

                button.btn-icon-40(
                    @click="openWatchlist(false)"
                )
                    IconSet(type="close")

            .popup-container__body
                .mb-2.pb-2
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Spinner from '@/components/Spinner.vue';


export default {
    name: 'vehicle-index',
    components: {
        IconSet,
        DateRangePicker,
        VueAdsPagination,
        VueAdsPageButton,
        Spinner
    },

    data() {
        return {
            tableThead: [
                {
                name: "Plat Police",
                },
                {
                name: "Created By",
                },
                {
                name: "Created At",
                },
                {
                name: "",
                action: true,
                },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: "",
            timeout: null,
            form_type: true,
            id_selected: '',

            model: {
                plate_number: "",
            },

            error_plate_number: "",
            openPopUp: false,
            popUpWatchlist: false,
            dateRange: {
                startDate: '',
                endDate: '',
            },

            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            data: [
                {
                    plat: 'A 1234 B',
                    created_by: 'Operator 1',
                    created_at: '10/20/22 12:00:21',
                },
                {
                    plat: 'AB 0987 XYZ',
                    created_by: 'Operator 1',
                    created_at: '10/20/22 12:00:21',
                },
                {
                    plat: 'B 9475 DX',
                    created_by: 'Operator 1',
                    created_at: '10/20/22 12:00:21',
                },
                {
                    plat: 'A 01 xyz',
                    created_by: 'Operator 1',
                    created_at: '10/20/22 12:00:21',
                },
                {
                    plat: 'AD 1234 XY',
                    created_by: 'Operator 1',
                    created_at: '10/20/22 12:00:21',
                },
            ],

            typing_search: '',
            targetSelected: false,

            dataResult: [
                { plat: 'D 1234 AS'},
                { plat: 'D 5678 FG'},
                { plat: 'D 9012 DS'},
                { plat: 'D 5678 ER'},
                { plat: 'D 0155 DS'},
                { plat: 'D 2352 FA'},
                { plat: 'D 8978 DF'},
                { plat: 'D 8932 AS'},
                
            ],

            is_date_filter: false,
        }
    },
    validations: {
        model: {
        plate_number: {
            required,
        },
        },
    },   

    computed: {
        ...mapState('auth', ['user']),
        ...mapState("vehicle", [
            "status_list",
            "list",
            "detail",
        ]),
        route_name() {
            return this.$route.name;
        },
    },  

watch: {
        route_name(newData) {
            if (newData === "vehicle-Index") {
                this.items_per_page = 10;
                this.page = 0;
                this.keyword = "";
                this.$store.commit("vehicle/setList", {});
                
                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        detail(newData) {
            if (this.form_type === false) {
                this.id_selected = (newData.id ? newData.id : '');
                this.model.plate_number = (newData.plate_number ? newData.plate_number : '');
            }
        },        
    },    

    methods: {
        getData() {
            this.reset()
            let params = [this.items_per_page, (this.page + 1), this.keyword, this.order_by];

            if (this.is_date_filter) {
                params.push(this.from_date, this.to_date);
            }

            if (this.route_name === "vehicle-index") {
                this.$store.dispatch("vehicle/getList", params);
                this.id_selected = '';
            }
        },
        editModal(id) {
            this.openModal(true);
            this.form_type = false;
            this.id_selected = id;
            this.getDetail(id);
        },
        getDetail(id) {
            this.reset();
            EventEmit.$emit('showLoading', false);

            this.$store.dispatch('vehicle/getDetail', id)
            .then((resp) => {
                if (resp.result === 'success') {

                    EventEmit.$emit('showLoading', false);

                } else {
                    EventEmit.$emit('error');
                }
            });

        },
        reset() {
            this.$store.commit('vehicle/setDetail', {});
            this.id_selected = '';
            this.setEmptyModel();
            // reset vuelidate
            this.$nextTick(() => { this.$v.$reset(); });
            this.setErrors(true);
            window.scrollTo(0,0);
        }, 
        setEmptyModel(all=true) {
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    this.model[Object.keys(this.model)[key]] = '';
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_plate_number = '';

            } else if (empty !== true && errors !== null) {
                this.error_plate_number = (errors.error_plate_number ? errors.error_plate_number : '');
            }
        },               
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                        if (this.$v.model[input].$error) {
                            break;
                        }
                }
                window.scrollTo(0,0);
                return;
            }

            EventEmit.$emit('showLoading', true);

            let model_data = Object.keys(this.model).map((key) => this.model[key]);

            if (this.form_type === true) {

                this.$store.dispatch('vehicle/create', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.openModal(false);
                        this.getData();                        
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully created!',
                            timer: 3000,
                        });

                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('showLoading', false);
                            this.$swal.fire({
                            title: 'Data Already Exist!',
                            icon: 'error',
                            });                            
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });

            } else {

                model_data = [this.id_selected].concat(model_data);

                this.$store.dispatch('vehicle/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {   
                        this.openModal(false);
                        this.getData();                     
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Data successfully updated!',
                            timer: 3000,
                        });

                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            EventEmit.$emit('showLoading', false);
                            window.scrollTo(0,0);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            }
        },
        deleteData(id, name) {
            this.$swal
                .fire({
                icon: "warning",
                title: "Are you sure you want to delete?",
                text: "Plate Police : " + name,
                confirmButtonText: "Yes!",
                confirmButtonColor: "#DD6B55",
                showCancelButton: true,
                allowOutsideClick: false,
                })
                .then((result) => {
                if (result.isConfirmed) {
                    EventEmit.$emit("showLoading", true);

                    this.$store.dispatch("vehicle/delete", [id]).then((resp) => {
                    if (resp === "success") {
                        this.page = 0;

                        this.getData();

                        this.$swal.fire({
                        icon: "success",
                        title: "Data successfully deleted!",
                        timer: 3000,
                        });
                    } else {
                        if (resp.data && resp.data.message) {
                        EventEmit.$emit("error", resp.data.message);
                        } else {
                        EventEmit.$emit("error");
                        }
                    }
                    });
                }
                });
        },
        pageChange(page) {
            this.page = page;
        },
        rageChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit("vehicle/setList", {});
                self.getData();
            }, 650);
        },

        openModal(open = true) {
            // hide scroll bar if true
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.openPopUp = !this.openPopUp
            this.typing_search = ''
        },

        openWatchlist(open = true) {
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.popUpWatchlist = !this.popUpWatchlist
        },

        searchHandler() {
            console.log(this.typing_search)
        },

        clearSearch() {
            this.typing_search = ''
            this.targetSelected = false
        },

        async pickerUpdate(e) {
            // console.log('========= DateRangePicker on update')
            // console.log(e)
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            if (ranges > this.$store.getters['config/getValidateRange']) {
                let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(default_date.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date, 'YYYY-MM-DD').toDate();
    
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                
                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRange'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })
                // return;
            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            }
        },        
        filterList() {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.is_date_filter = true

            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit("vehicle/setList", {});
                self.getData();
            }, 650);
        },

    },

    created() {
        this.getData();
    },
    mounted() {
            const default_date = this.$store.getters['config/getDefaultDate'](14);

            this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD').toDate();
            this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD').toDate();

            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
}
</script>


<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px

</style>